.loading-spinner {
    width: 45vw;
    max-width: 310px;
    animation: rotation 15s infinite linear;
}

.loading-star {
    position: absolute;
    width: 10vw;
    max-width: 85px;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}
