.circular-profile-picture {
    position: relative;
    width: 50vw;
    max-width: 288px;
    height: 50vw;
    max-height: 288px;
    overflow: hidden;
    border-radius: 50%;
    border: 3px solid #000000;
    z-index: 100;
}

.circular-profile-picture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
