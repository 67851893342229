.profile{
    width: 300px;
    padding: 25px;
}

.links-icon{
    padding: 10px;
}

.svg-img {
    max-width: 100vw;
}