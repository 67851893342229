.header{
    height: 70px;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    display: flex;
    align-items: center;
}

.fullscreen-header{
    height: 70px;
    top: 0px;
    left: 0px;
    width: 100vw;
    display: flex;
    align-items: center;
}


.home {
    font-family: 'Abril Fatface', cursive;
    color: white;
    font-size: 32px;
    position: relative;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 15px;
    z-index: 999;
    width: 100%;
    text-align: center;
}

.about {
    font-family: 'Unna', serif;
    color: white;
    font-size: 20px;
    position: relative;
    text-align: right;
    word-wrap: normal;
    z-index: 999;
    white-space: nowrap;
    width: auto;
    margin-right: 20px;
    padding-bottom: 7px;
}

.pointer {
    cursor: pointer;
}


